

/* eslint-disable */
import React, { useEffect, useState } from "react";
// import AppLayout from "../../Loyout/App";
import DateAndTimeLayout from "../../Common/DateAndTimeLayout";
import IconGallery from "../../../assets/images/IconGallery.svg";
import { useFormik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import API from "../../../Api/Api";
import AdminRoute from "./../../../Route/RouteDetails";
import baseApi from "../../../Api/config";
import { ToastContainer, toast } from "react-toastify";


const AddCategoryModel = ({ closeModal, categoriesList, getCategoryDataList }) => {
    const adminObject = JSON.parse(localStorage.getItem("TajurbaAdminToken"));

    const [errorMessage, SetErrorMessage] = useState("");
    const [uploadedFile, setUploadedFile] = useState("");
    const [loading, setLoading] = useState(false);

    const [editingCategory, setEditingCategory] = useState(null);
    const [categoryIcon, setCategoryIcon] = useState(null);
    const [categoryId, setCategoryId] = useState(null);

    const [searchTerm, setSearchTerm] = useState(""); // State for search term

    // Handle search input change
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // Handle clear button click
    const clearSearch = () => {
        setSearchTerm("");
    };


    const categorySaveValidate = (values) => {
        console.log(values, "value");
        const errors = {};

        if (!values.name) {
            errors.name = "Please enter category title";
        } else if (values.name.trim() === "") {
            errors.name = "Title cannot be blank";
        }

        if (!values.uploadedFile) {
            errors.uploadedFile = "Please upload icon";
        }
        if (values.status === "") {
            errors.status = "Please select status";
        }

        console.log("Erroes", errors);
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            name: "",
            uploadedFile: "",
            status: ""
        },
        validate: categorySaveValidate, // Pass the validate function here
        onSubmit: (values, { setSubmitting }) => {
            const errors = categorySaveValidate(values);

            if (Object.keys(errors).length === 0) {
                // console.log("Run vaidation function no errors");
                handleCategorySave();
            } else {
                // console.log("Run vaidation function if errors is present ");
                console.log("Validation errors:", errors);
            }

            setSubmitting(false);
        },
        categorySaveValidate,
    });

    const handleCategorySave = () => {
        console.log("handleCategorySave called..");
        SetErrorMessage("");
        setLoading(true);

        const categoryData = {
            category_name: formik.values.name,
            image: formik.values.uploadedFile,
            is_active: formik.values.status === "true" ? true : false,
        };
        if (editingCategory) {
            categoryData.category_id = categoryId;
            categoryData.sequence = null;
        }
        const apiEndpoint = editingCategory ? "UpdateCategories" : "AddCategories";
        try {
            API?.CommanApiCall({
                data: categoryData,
                agent: "categories",
                function: apiEndpoint,

            }).then((response) => {
                console.log("Api resssss----->>>>>>>", response?.data?.data);
                if (response?.data?.data?.status === 200) {
                    setLoading(false);
                    console.log("-------------successss");
                    //update the category list
                    getCategoryDataList();
                    //Reset the fields
                    handleCancelEdit();
                } else if (response?.data?.data?.status === 201) {
                    SetErrorMessage(response?.data?.data?.message);
                    setTimeout(() => {
                        SetErrorMessage("");
                    }, 5000);
                }
            });
        } catch (error) {
            console.log("error------->>>>>>>>>", error);
        }
    };

    // Api for FIle Upload
    const UploadIconFile = (e) => {
        // console.log("e", e.target.value);
        const file = e?.target?.files[0];
        const allowedTypes = ["image/jpeg", "image/png"];

        if (allowedTypes?.includes(file?.type)) {
            var myHeaders = new Headers();

            myHeaders.append("x-access-token", adminObject);

            var formdata = new FormData();
            formdata.append(
                "file",
                e.target.files[0]
                // "Screenshot from 2023-01-05 12-20-01.png"
            );
            formdata.append("action", "formcommand");
            formdata.append("docType", "profile");

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };

            fetch(
                baseApi?.baseurl,
                // "https://server.qodequay.com/tajurba/dev/api/api/apppipeline/",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    formik.setFieldValue("uploadedFile", result?.data?.data?.Location);
                    // setUploadedFile(result?.data?.data?.Location);
                })
                .catch((error) => console.log("error", error));
        } else {
            toast.error("Only jpg or png should be allowed");
        }
    };


    const handleEditCategory = (categoryData) => {
        setEditingCategory(true);
        setCategoryId(categoryData.category_id);

        formik.resetForm({
            values: {
                name: categoryData.category_name,
                uploadedFile: categoryData.image || "",
                status: categoryData.is_active ? "true" : "false",
            },
        });

        formik.setTouched({
            name: false,
            uploadedFile: false,
            status: false,
        });

        SetErrorMessage("");
    };

    const handleCancelEdit = () => {
        setEditingCategory(false);
        setCategoryId(null);

        formik.resetForm({
            values: {
                name: "",
                uploadedFile: "",
                status: "",
            },
        });

        formik.setTouched({
            name: false,
            uploadedFile: false,
            status: false,
        });

        SetErrorMessage("");
    };

    // Filter categories based on the search term
    const displayedCategories = categoriesList.filter((category) =>
        category.category_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            {/* Modal */}
            <div
                className="modal fade show"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "900px" }}>
                    <div className="modal-content border-radius-5 border-top-model-black">
                        <div className="modal-header border-0">
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeModal}
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body" style={{ height: "675px", overflowY: "auto" }}>
                            <div className="container">
                                <div className="row">
                                    {/* Form for creating or editing category */}
                                    <div className="col-md-6">
                                        <h3 className="headText mt-2 mb-2 fw-bold">{editingCategory ? "Edit Category" : "Create Category"}</h3>

                                        <label className="form-label mb-3">
                                            <span className="text-muted">
                                                <span className="pagination__desc d-flex align-items-center">
                                                    {editingCategory ? "Update category details" : "Add new category below"}
                                                </span>
                                            </span>
                                        </label>


                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="form-group">
                                                <label className="form-label">
                                                    <span className="mandatory-star me-1">*</span>
                                                    Category
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    placeholder="Enter Category Name"
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                />
                                                {formik.errors.name && formik.touched.name && (
                                                    <div className="text-danger">{formik.errors.name}</div>
                                                )}
                                            </div>

                                            <div className="form-group mt-3">
                                                <label className="form-label">
                                                    <span className="mandatory-star me-1">*</span>
                                                    Category Icon
                                                </label>
                                            </div>

                                            <div className="col-12 float-start mb-4 position-relative">
                                                <p
                                                    class="addUserPic border-dash p-0 w-30 mt-1 mb-1 d-flex justify-content-center align-items-center"
                                                    style={{
                                                        height: "115px", border: "1px dashed", overflow: "hidden"
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span class="text-center">
                                                            {formik.values.uploadedFile ? (
                                                                <img
                                                                    src={formik.values.uploadedFile}
                                                                    alt=""
                                                                    className="w-100"
                                                                    id="profile-picture-custome"
                                                                    style={{ height: "115px" }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    <img
                                                                        src={IconGallery}
                                                                        alt=""
                                                                        className="mb-2"
                                                                    />
                                                                    <br />
                                                                    <NavLink className="textBlue font-size-12 ">
                                                                        Upload Icon
                                                                    </NavLink>
                                                                </>
                                                            )}
                                                            <br />
                                                        </span>
                                                    </div>

                                                    <input
                                                        type="file"
                                                        class="custom-file-input"
                                                        id="customFile"
                                                        name="uploadedFile"
                                                        accept="image/jpeg, image/png"
                                                        onChange={(e) => {
                                                            UploadIconFile(e);
                                                        }}
                                                    />
                                                    <label
                                                        class="custom-file-label mb-0"
                                                        htmlForfor="customFile"
                                                    ></label>

                                                </p>

                                                <small className="form-text text-muted">
                                                    Note: Use only png/jpeg
                                                </small>

                                                {formik.errors.uploadedFile &&
                                                    formik.touched.uploadedFile ? (
                                                    <div className="text-danger">
                                                        {formik.errors.uploadedFile}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className="form-group mt-3">
                                                <label className="form-label">
                                                    <span className="mandatory-star me-1">*</span>
                                                    Category Status
                                                </label>
                                                <div className="d-flex">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="status"
                                                            value="0"  // Active will be saved as true
                                                            checked={formik.values.status === "true"}
                                                            onChange={() => formik.setFieldValue("status", "true")}
                                                        />
                                                        <label className="form-check-label">Active</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="status"
                                                            value="1"  // Inactive will be saved as false
                                                            checked={formik.values.status === "false"}
                                                            onChange={() => formik.setFieldValue("status", "false")}
                                                        />
                                                        <label className="form-check-label">Inactive</label>
                                                    </div>
                                                </div>
                                                {formik.errors.status && formik.touched.status && (
                                                    <div className="text-danger">{formik.errors.status}</div>
                                                )}
                                            </div>

                                            <button
                                                type="submit"
                                                className="btn btn-primary mt-3"
                                                onClick={formik.handleSubmit}
                                            >
                                                {editingCategory ? "Update Category" : "Add Category"}
                                            </button>
                                            {editingCategory && (
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary mt-3 ms-2"
                                                    onClick={handleCancelEdit}
                                                >
                                                    Cancel
                                                </button>
                                            )}
                                        </form>
                                    </div>

                                    {/* Scrollable Category Listing */}
                                    <div className="col-md-6">
                                        <h3 className="headText mt-2 mb-2 fw-bold">Category Listing</h3>

                                        {/* Search box */}
                                        <div className="input-group mb-3" style={{ width: '100%' }}>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search category..."
                                                value={searchTerm}
                                                onChange={handleSearch}
                                                style={{ flex: 1 }}
                                            />
                                            {searchTerm && (
                                                <button className="btn btn-outline-secondary" type="button" onClick={clearSearch}>
                                                    <span className="btn-close" aria-hidden="true"></span>
                                                </button>
                                            )}
                                        </div>

                                        {/* Category listing */}
                                        <div
                                            className="list-group"
                                            style={{
                                                maxHeight: "500px",
                                                overflowY: "auto",
                                                border: "1px solid #ddd",
                                            }}
                                        >
                                            {displayedCategories.slice().reverse().map((ele, index) => (
                                                <div key={ele.id} className="list-group-item d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={ele.image || IconGallery}
                                                            alt="Icon"
                                                            width="40"
                                                            height="40"
                                                            className="me-3"
                                                        />
                                                        <span>{ele.category_name}</span>
                                                    </div>
                                                    {ele.editable === true && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary btn-sm"
                                                            onClick={() => handleEditCategory(ele)}
                                                        >
                                                            Edit
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};


export default AddCategoryModel;
